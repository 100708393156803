@import "../../constants";
@import "../../mixins";

h3.wizardSubHeader {
  margin-bottom: 24px;
}

.wizardCTA {
  // The Next/Back buttons at bottom
  display: flex;
  flex-direction: row-reverse;

  .wizardCTAContent {
    display: contents;
  }
}

.wizardFilesList {
  ul {
    list-style-type: none;
  }

  li {
    @include fontSize12;
    font-weight: 500;
    color: $text-color;
    margin: 0 0 6px 0;

    .iconFile {
      margin-right: 6px;
      vertical-align: bottom;
    }
  }
}

.wizardText {
  @media (max-width: $breakpoint-sm) {
    padding: 0 24px;
  }
}
