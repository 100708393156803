@import "../../constants";
@import "../../mixins";

$topIndent: 18px;
$leftIndent: $form-left-indent;

.container {
  margin-bottom: 30px;
  padding-top: $topIndent;

  input {
    @include fontSize12;
    padding: 0 $leftIndent 10px $leftIndent;
    display: block;
    border: none;
    border-bottom: 1px solid $soft-gray5;
    width: 100%;
    color: $soft-black;

    &:disabled {
      color: $disabled-color;
      border-bottom-color: $disabled-color;
    }
  }

  label {
    top: $topIndent;
    left: $leftIndent;
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    @include fontSize12;
    color: $soft-gray4;
  }

  &.focus,
  &.filled {
    label {
      top: 0;
      @include fontSize10;
    }
  }

  &.focus {
    input {
      border-bottom-color: #ff7c74;
    }
  }

  &.withError {
    input {
      border-bottom-color: $coral-dark3;
    }

    .error {
      @include fontSize10;
      color: $coral-dark3;
      padding: 2px $leftIndent 0;
    }
  }
}
