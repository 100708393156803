@import "../../../constants";

.container {
  .stats {
    margin: 12px 0 18px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e8eaef;
      height: 44px;

      &:last-child {
        border-bottom: 0;
      }

      .label {
        font-family: "Centra No2", sans-serif;
        font-size: 14px;
        line-height: 21px;
        color: $soft-gray5;

        span {
          font-weight: 500;
        }
      }

      .value {
        font-family: "F37Bobby", sans-serif;
        font-size: 19px;
        line-height: 28px;
        color: $soft-black;
      }
    }
  }

  & > button {
    width: 100%;
  }

  @media (min-width: $breakpoint-sm) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    & > button {
      width: fit-content;
    }

    .stats {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        display: block;
        border-bottom: 0;
        margin-right: 65px;

        &:last-child {
          margin-right: 0;
        }

        .label {
          margin-bottom: 3px;
        }

        .value {
          font-size: 27px;
          line-height: 36px;
        }
      }
    }
  }
}
