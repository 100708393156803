@import "constants";
@import "mixins";

body {
  margin: 0;
  overflow-x: hidden;
}

* {
  position: relative;

  outline: 0;
  margin: 0;
  border: none;
  padding: 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  //-webkit-transition: all 0.3s ease;
  //-moz-transition: all 0.3s ease;
  //-ms-transition: all 0.3s ease;
  //-o-transition: all 0.3s ease;
  //transition: all 0.3s ease;

  background-color: transparent;
  background-repeat: no-repeat;
}

@font-face {
  font-family: "F37Bobby";
  src: url("./assets/fonts/F37Bobby-Regular.woff2") format("woff2"),
    url("./assets/fonts/F37Bobby-Regular.eot") format("embedded-opentype"),
    url("./assets/fonts/F37Bobby-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Centra No2";
  src: url("./assets/fonts/CentraNo2-Book.woff2") format("woff2"),
    url("./assets/fonts/CentraNo2-Book.eot") format("embedded-opentype"),
    url("./assets/fonts/CentraNo2-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Centra No2";
  src: url("./assets/fonts/CentraNo2-Medium.woff2") format("woff2"),
    url("./assets/fonts/CentraNo2-Medium.eot") format("embedded-opentype"),
    url("./assets/fonts/CentraNo2-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

%text {
  @include fontSize12;
  font-family: "Centra No2";
  font-weight: 400;
}

%header {
  @extend %text;
  color: $text-color;
  font-family: "F37Bobby";
}

h1.hero {
  @extend %header;
  @include fontSize36;
  margin-bottom: 0; // handled by line height

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 8px;
  }
}

h1 {
  @extend %header;
  @include fontSize24;
  margin-bottom: 0;
}

h2 {
  @extend %header;
  @include fontSize16;
}

h3 {
  @extend %header;
  @include fontSize14;
}

h4 {
  margin: 18px 0;
  font-family: "Centra No2";
  font-weight: 400;
  @include fontSize14;
}

p {
  @extend %text;
  color: $text-color;
  margin-bottom: 20px;
}

a {
  color: $link-color;
  text-decoration: none;
  &:visited {
    color: $link-color;
    // color: $clicked-link-color;  // Zander: this looks bad;
  }
  &:hover {
    color: $hover-link-color;
  }
}

input {
  appearance: none;
  border-radius: 0;
}

input[type="date"] {
  font-family: "Centra No2";
}

div {
  @extend %text;
}

button {
  @extend %text;
  font-size: 14px;
  line-height: 21px;
}

body {
  font-family: "Open Sans";
}

// Instructions text
.instructions {
  @include fontSize12;
  text-align: center;
  margin: 16px 24px;

  @media (min-width: $breakpoint-sm) {
    @include fontSize14;
    font-size: 14px;
    line-height: 21px;
  }
}

// Secondary actions, ex. "Back"
.secondaryAction {
  @include fontSize14;
  color: $soft-gray6;
}

.secondaryAction a {
  @include fontSize14;
  color: $link-color;
  &:hover {
    color: $hover-link-color;
  }
}

// Secondary instructions, ex. "If you do not have your files..."
.secondaryInstructions {
  @media (max-width: $breakpoint-sm) {
    padding: 10px 16px;
  }
}

// Text and links that do not call attention to themselves
.modest {
  @include fontSize10;
  color: $greyed-out-text;
}

.modest a {
  @include fontSize10;
  color: $soft-gray6;
  &:hover {
    color: $soft-black;
  }
  &:visited {
    color: $soft-gray6;
  }
}

// Some shitty approach here

button.asLink {
  @include fontSize12;
  color: $coral;
  text-decoration: underline;
  cursor: pointer;
}
