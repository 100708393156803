@import "../../../constants.scss";

.container {
  margin: 40px -25px;

  .item {
    margin: 0 auto 20px;
    border-radius: 5px;
    padding: 45px 20px 40px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(40, 45, 55, 0.1);
    text-align: center;
    width: 250px;
    height: 100%;

    .icon {
      margin: 0 auto 25px;
      width: 85px;

      img {
        display: block;
        width: 100%;
      }
    }

    .text {
      color: $soft-black;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  @media (min-width: $breakpoint-sm) {
    margin: 40px auto;
    max-width: 1060px;

    .item {
      width: 100%;
    }
  }
}
