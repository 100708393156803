@import "../../../constants.scss";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.primary,
button.secondary {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 2px solid $dark-blue2;
  border-radius: 2px;
  padding: 10px 35px;
  width: fit-content;
  font-family: "Centra No2", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }

  &.withLoader {
    cursor: not-allowed;

    &:before {
      display: block;
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin-top: -14px;
      margin-left: -14px;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: spin 2s linear infinite;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.small {
    padding: 8px 12px;
  }

  &.noBorder {
    border: 0;
  }

  &.fullWidthOnMobile {
    width: 100%;

    @media (min-width: $breakpoint-sm) {
      width: fit-content;
    }
  }
}

button.primary {
  background-color: $dark-blue2;
  color: white;

  &.disabled {
    border-color: #d2d4da;
    background-color: #d2d4da;
    color: $soft-gray5;
  }

  &.withLoader {
    &:before {
      background-color: $dark-blue2;
    }

    &:after {
      border-top-color: $dark-blue2;
    }
  }
}

button.secondary {
  background-color: white;
  color: $dark-blue2;

  &.disabled {
    border-color: #d2d4da;
    color: $soft-gray5;
  }

  &.withLoader {
    &:before {
      background-color: white;
    }

    &:after {
      border-top-color: white;
    }
  }
}
