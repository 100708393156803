@import "../../../constants";
@import "../../../mixins";

$menu-height-mobile: 68px;
$menu-height-desktop: 105px;
$footer-height-mobile: 68px;

.container {
  padding-top: $menu-height-mobile;
  padding-bottom: $footer-height-mobile;
  min-height: 100vh;

  @media (min-width: $breakpoint-sm) {
    padding-top: $menu-height-desktop;
    padding-bottom: 0;
  }
}

.header {
  position: fixed;
  top: 0;
  padding: 0 23px;
  height: $menu-height-mobile;
  background-color: white;
  z-index: 200;
  width: 100%;
  border-bottom: 2px solid $soft-gray2;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $menu-height-mobile - 2px;

    .title {
      display: flex;
      align-items: center;
      font-family: "F37Bobby", sans-serif;
      font-size: 19px;
      line-height: 28px;
      color: $soft-black;

      .backButton {
        display: block;
        margin-right: 24px;
        width: 10px;
        height: 16px;
        background-image: url("../../../assets/icons/arrow_left_gray_13.svg");
        background-size: 10px 16px;
        cursor: pointer;
      }
    }

    .actions {
      > * {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: $breakpoint-sm) {
    height: $menu-height-desktop;

    .inner {
      height: $menu-height-desktop - 2px;

      .title {
        font-size: 27px;
        line-height: 36px;

        .backButton {
          margin-right: 25px;
          width: 13px;
          height: 20px;
          background-size: 13px 20px;
        }
      }
    }
  }

  @media (min-width: $breakpoint-lg) {
    .inner {
      .title {
        .backButton {
          margin-right: 52px;
          margin-left: 32px;
        }
      }
    }
  }
}

.main {
  padding: 0 20px;

  @media (min-width: $breakpoint-sm) {
    padding: 20px;
  }
}

.footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  padding: 0 20px;
  height: $menu-height-mobile;
  background-color: white;
  z-index: 200;
  width: 100%;
  border-top: 2px solid $soft-gray2;
}

.removeAdditional {
  cursor: pointer;
  height: 45px;
  width: 100%;
  min-width: 45px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > span {
    padding-left: 30px;
    line-height: 56px;
    color: $dark-blue2;
    font-weight: 500;
    font-size: 15px;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
    width: 45px;
    max-width: 45px;
    background-position: center;

    & > span {
      display: none;
    }
  }

  background-image: url("../../../assets/icons/circle_minus_blue_18.svg");
  background-repeat: no-repeat;
  background-position: left center;
}
