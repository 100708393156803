@import "../../constants.scss";

.innerContainer {
  margin-bottom: 100px;
  padding: 40px 20px;
  text-align: center;

  .label {
    margin-bottom: 20px;
    color: $coral;
    font-family: "Centra No2", sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: 25px;
  }

  .sum {
    margin-bottom: 20px;
    color: $soft-black;
    font-family: "F37 Bobby", sans-serif;
    font-size: 35px;
    letter-spacing: -0.01px;
    line-height: 44px;
  }

  .description {
    margin-bottom: 30px;
    color: $soft-black;
    font-family: "Centra No2", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 23px;
  }

  @media (min-width: $breakpoint-sm) {
    padding: 50px;

    .label {
      margin-bottom: 25px;
      font-size: 25px;
      letter-spacing: -0.16px;
    }

    .sum {
      margin-bottom: 30px;
      font-size: 90px;
      letter-spacing: -0.02px;
      line-height: 90px;
    }

    .description {
      font-size: 20px;
      letter-spacing: -0.13px;
      line-height: 30px;
    }
  }
}

.info {
  position: absolute;
  right: 20px;
  bottom: 20px;
  left: 20px;
  opacity: 0.75;
  color: $soft-black;
  font-family: "Centra No2", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 700px;
  }
}
