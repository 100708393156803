@import "../../constants.scss";

.row {
  margin-bottom: -25px;

  .col {
    padding-bottom: 25px;

    &:empty {
      padding-bottom: 0;
    }
  }

  @media (min-width: $breakpoint-sm) {
    display: flex;
    margin: -20px -10px;

    .col {
      flex: 1;
      padding: 20px 10px;
    }
  }
}
