@import "../../constants";
@import "../../mixins";

.container {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $soft-gray2;

    &:last-child {
      border-bottom: 0;
    }

    @media (min-width: $breakpoint-sm) {
      padding: 24px;
    }

    .arrow {
      height: 24px;
      width: 24px;

      img {
        display: block;
        width: 100%;
      }
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        box-shadow: 0px 2px 10px rgba(107, 114, 127, 0.3);
        border-radius: 8px;
        z-index: 2;
      }
    }
  }
}
