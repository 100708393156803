@import "../../constants";
@import "../../mixins";

/* Main Header */
@mixin navSelected {
  color: $text-color;
  border-bottom: 2px solid $link-color;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $menu-height-mobile;
  padding: 0 12px;
  background-color: white;
  box-shadow: $header-shadow;
  z-index: 200;
  position: fixed;
  width: 100%;

  &:hover {
    background: white;
    box-shadow: $header-shadow;
  }

  @media (min-width: $breakpoint-sm) {
    display: flex;
    flex-direction: row;
    height: $menu-height-web;
    background-color: transparent;
    box-shadow: none;
  }

  &.fullHeader {
    justify-content: space-between;
  }

  &.simpleHeader {
    background-color: white;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  %linkFont {
    font-family: Centra No2;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $soft-gray6;
  }

  a,
  .button {
    @extend %linkFont;
    cursor: pointer;
    padding: 6px 0;

    @media (min-width: $breakpoint-sm) {
      border-bottom: 2px solid transparent;
      margin: 0 21px;

      &.selected {
        @include navSelected;
      }

      &:hover {
        // font-weight: 500;  // This causes text to grow...
        color: $text-color;
      }

      &:visited {
        color: $soft-gray6;
      }
    }

    &.secondary {
      background-color: transparent;
      color: $coral;
      border-color: $coral;
      border-radius: 24px;
      border: 1px solid;
      padding: 8px 24px;

      &:hover {
        background-color: rgba(245, 85, 75, 0.1);
      }

      &:active {
        background-color: rgba(245, 85, 75, 0.6);
      }

      &:visited {
        color: $coral;
      }
    }
  }

  .logo img {
    display: block;
    height: 16px;
  }

  .logoNoHover,
  .logoNoHover:hover {
    border-bottom: none;
  }
}

@media (min-width: $breakpoint-sm) {
  .highlightHeader {
    background-color: white;
    box-shadow: 0px 1px 3px rgba(40, 45, 55, 0.2),
      0px 2px 1px rgba(40, 45, 55, 0.12), 0px 1px 1px rgba(40, 45, 55, 0.14);
  }
}

.dropdownMenu {
  // Positioning handled in makestyles

  a {
    color: $soft-gray6 !important;
  }

  li {
    min-width: 230px;
  }
}

/* Drawer */

.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.drawer .drawerHeader {
  background: #f3f5f9;
  padding-top: 24px;
  padding-bottom: 16px;
  text-align: center;

  .logoIcon {
    width: 55px;
    margin: 30px auto;
  }
}

.drawer .drawerHeader a {
  text-decoration: none;
}

.drawer .drawerHeader .avatar {
  margin: auto auto 8px;
}

.drawer .drawerHeader .profileName {
  text-align: center;
  color: #282d37;
}

.drawer .drawerHeader .profileName a {
  font-family: F37Bobby;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #282d37;
}

.drawer .drawerContent {
  padding: 24px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer .drawerContent a {
  text-decoration: none;
}

.drawer .drawerContent .drawerNav li {
  font-family: "Centra No2";
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  list-style-type: none;
}

.drawer .drawerContent .drawerNav li a {
  color: #4e545e;
  padding-bottom: 4px;

  &.selected {
    @include navSelected;
  }
}

.drawer .drawerContent .drawerFooter .linkSmall {
  font-family: "Centra No2";
  font-size: 10px;
  line-height: 18px;
  color: #abaeb5;
  margin-bottom: 14px;
  margin-right: 40px;
}

.drawer .drawerContent .companyName {
  color: #abaeb5;
}

/* Page Header */

.pageHeader {
  padding: 74px 28px 56px;
  background-position: center; // For if there's an image

  @media (max-width: $breakpoint-sm) {
    min-height: 186px;
  }

  @media (min-width: $breakpoint-sm) {
    padding: 116px 28px 70px;
  }

  &.noPaper {
    padding-left: 30px; // No have Paper element overlapping on top of PageHeader
    padding-right: 30px;
    padding-top: 28px + $menu-height-web;
    padding-bottom: 28px;
  }

  &.pageHeaderImage {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;

    img {
      display: block;
      width: 100%;
    }
  }

  h1.hero {
    margin-bottom: 10px;
  }

  p {
    @include fontSize14;
    margin: 0;
    font-family: "F37Bobby";
  }

  .container {
    @include singleColumn;
  }
}

.wide .pageHeader .container {
  @include twoColumns;
}

/* Main */
.main {
  @include singleColumn;

  @media (max-width: $breakpoint-sm) {
    padding: 16px 12px;
  }

  @media (min-width: $breakpoint-sm) {
    &:not(.mainNoMargin) {
      margin-top: 30px !important; // This messes up use of .main with MainLayout.Tabs
      margin-bottom: 60px;
    }
  }

  &.mainNoMargin {
    padding: 0;
  }
}

.wide .main {
  @include twoColumns;
  @media (min-width: $breakpoint-sm) {
    margin-bottom: 60px;
  }
}

/* Tabs */

.tab {
  padding: 24px 24px;

  @media (min-width: $breakpoint-sm) {
    padding: 32px 0;
  }
}

/* Pill */
.pillBackground {
  padding: 60px 0;
  background-color: #ffdcbe;
  min-height: 100vh;
}

.pill {
  max-width: 630px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: white;
  padding: 60px;
}

.pill .pillHeader {
  margin-bottom: 24px;
}

.pill h1,
h2 {
  font-family: F37Bobby;
  color: #282d37;
  letter-spacing: -0.3px;
}

.pill h1 {
  font-size: 42px;
  line-height: 58px;
  margin-bottom: 12px;
}

.pill h2 {
  font-size: 16px;
  line-height: 22px;
  color: #4e545e;
}

/* Progress Steps */

.stepsProgressBuffer {
  // Necessary StepsProgress to be below navigation
  min-height: 42px;

  @media (min-width: $breakpoint-sm) {
    min-height: 56px;
  }
}

.stepsProgress {
  display: flex;
}

.stepsProgressStep {
  flex: 1;
  margin-right: 1px;
  height: 4px;
  background-color: #c3e1f5;
}

.stepsProgressStep:last-child {
  margin-right: 0;
}

.stepsProgressStep.stepsProgressStepActive {
  background-color: #5eb8f4;
}

/* Actions */

.actions {
  text-align: center;

  > * {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: $breakpoint-sm) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    text-align: right;

    > * {
      margin-bottom: 0;
      margin-left: 18px;

      &:last-child {
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  }
}
