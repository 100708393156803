@import "../../../constants.scss";

.container {
  .section {
    padding: 20px 0;
    border-bottom: 2px solid $soft-gray2;
    max-width: 580px;

    @media (min-width: $breakpoint-sm) {
      border-bottom-width: 1px;
      padding: 32px 0;
    }

    div,
    p {
      font-family: "Centra No2", sans-serif;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: $soft-gray6;
    }

    p:last-child {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 30px;
      font-size: 19px;
      line-height: 28px;
      color: $soft-gray6;
    }

    h3 {
      font-family: "Centra No2", sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: $soft-gray6;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .lineItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .totalCost {
      font-weight: 500;
    }
  }
}
