@import "../../constants";
@import "../../mixins";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
  font-family: Centra No2;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: $soft-gray6;
  height: 56px;

  &:hover {
    cursor: pointer;
  }

  .avatar {
    font-size: 12px;
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }

  .userName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 190px;
  }

  .dropdown {
    display: none;
    position: absolute;
    top: 56px;
    left: -20px;
    right: -20px;
    border-radius: 0 0 4px 4px;
    background-color: white;
    box-shadow: $header-shadow;

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: white;
    }

    li {
      padding: 0 20px;

      a,
      button {
        margin: 0;
      }
    }
  }

  &:hover {
    .dropdown {
      display: block;
    }
  }
}

.item {
  cursor: pointer;
  padding: 12px 22px;
  @include fontSize14;

  &:hover {
    background-color: $soft-gray2;
  }
}
