@import "../../../constants.scss";

.control {
  input {
    display: block;
    width: 100%;
    height: 52px;
    border: 1px solid $soft-gray4;
    padding: 15px;
    border-radius: 3px;
    color: $soft-black;
    font-family: "Centra No2";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: 20px;

    @media (min-width: $breakpoint-sm) {
      font-size: 20px;
    }

    &::placeholder {
      color: $soft-black;
      opacity: 0.3;
      line-height: normal;
    }
  }
}
