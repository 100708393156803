@import "../../../constants.scss";

.overlay {
  position: fixed;
  z-index: 500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.control {
  min-width: 100%;
  display: flex;
  background-color: #f8f9fc;
  border: 1px solid #f8f9fc;
  border-bottom: 1px solid $soft-gray5;
  padding: 10px 11px 11px;
  border-radius: 2px 2px 0 0;

  @media (min-width: $breakpoint-sm) {
    min-width: initial;
  }

  input {
    flex: 1;
    display: block;
    width: 100%;
    color: $soft-black;
    font-family: "Centra No2";
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;

    &::placeholder {
      color: $soft-black;
      opacity: 0.3;
      line-height: normal;
    }
  }

  &.focused {
    border-color: #057ccc;
  }

  &.withError,
  &.withError.focused {
    border-color: $coral-dark;
    background-color: #fceeee;
  }
}

.dropdown {
  position: absolute;
  z-index: 560;
  top: 100%;
  width: 100%;
  background-color: white;
  border-top: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #f3f5f9;
  box-shadow: 0px 1px 9px rgba(40, 45, 55, 0.15);
  border-radius: 2px;

  &.positionTop {
    top: auto;
    bottom: calc(100%);
  }

  .option {
    cursor: pointer;
    padding: 11px 12px;
    font-family: "Centra No2";
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    border-bottom: 1px solid #e8eaef;
    color: $soft-gray5;

    &:last-child {
      border-bottom: 0;
    }

    &.selected {
      background-color: #f1f2f4;
    }

    &:hover {
      background-color: #f8f9fc;
    }
  }
}
