@import "../../constants";
@import "../../mixins";

.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;

  .innerContainer {
    padding: 12px;
    display: flex;
    align-items: flex-start;
    color: white;
    background: $dark-blue1;
    border-radius: 8px;

    .iconClock {
      padding-left: 2px;
      padding-right: 6px;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .subject {
        @include fontSize14;
      }
      .category,
      .summary {
        @include fontSize12;
      }
      .summary {
        max-width: 240px;
      }
    }
  }
}
