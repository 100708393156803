@import "../../constants";

.notifications {
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 67px;
  padding: 15px;
  transform: translateX(-50%);
  max-width: 480px;
  &.ignore {
    pointer-events: none;
  }

  width: 100%;

  @media (min-width: $breakpoint-sm) {
    top: 84px;
  }

  .notification {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 70px;
    margin-bottom: 10px;
    box-shadow: 0 5px 13px rgba(40, 45, 55, 0.1);
    border-radius: 2px;
    position: relative;
    padding: 15px 50px;
    border-width: 2px;
    border-style: solid;

    @media (min-width: $breakpoint-sm) {
      padding: 22px 60px;
    }

    transition: all 300ms ease-in;

    &.success {
      background: #eff8f3;
      border-color: #2f9b6b;
      .statusIcon {
        color: #2f9b6b;
      }
    }

    &.error {
      .statusIcon {
        color: #f5554b;
      }

      background-color: #fceeee;
      border-color: #f5554b;
    }

    .close {
      position: absolute;
      width: 12px;
      height: 12px;
      top: 12px;
      right: 12px;
      color: #6b727f;
      cursor: pointer;
    }

    .statusIcon {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 22px;
      top: 18px;

      @media (min-width: $breakpoint-sm) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text {
      font-family: Centra No2, monospace;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #282d37;
    }
  }

  .appear {
    opacity: 0.01;
    transform: translateY(-100%);
  }

  .appear.appearActive {
    opacity: 1;
    transform: none;
  }

  .appear.appearDone {
    opacity: 1;
    transform: none;
  }

  .exit {
    opacity: 1;
    transform: none;
  }

  .exit.exitActive {
    opacity: 0.01;
    transform: translateY(100%);
    z-index: 998;
  }

  .exit.exitDone {
    z-index: 998;
    transform: translateY(100%);
    opacity: 0.01;
  }

  .enter {
    transform: translateY(-100%);
    opacity: 0.01;
  }

  .enter.enterActive {
    opacity: 1;
    transform: none;
  }

  .enter.enterDone {
    opacity: 1;
    transform: none;
  }
}
