@import "../../constants";

.container {
  @media (min-width: $breakpoint-sm) {
    display: flex;
    margin: 0 -20px;

    .column {
      flex: 1 0 50%;
      padding: 0 20px;
      width: 50%;
    }
  }

  &.forceMobile {
    display: flex;
    margin: 0 -20px;

    .column {
      flex: 1 0 50%;
      padding: 0 20px;
      width: 50%;
    }
  }
}
