@import "../constants";

$containerHeight: 100vh;

.chatDetail {
  width: 315px;
}

.container {
  .innerChatContainer {
    flex: 1;
    height: $containerHeight;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .messageContainer {
      padding: 16px;
      flex-grow: 1;
      overflow: scroll;
    }
  }

  @media (min-width: $breakpoint-sm) {
    display: flex;

    .goalsList {
      width: 322px;
      height: $containerHeight;
      border-right: 1px solid $soft-gray2;
      overflow-y: scroll;
    }

    .innerChatContainer {
      height: $containerHeight;
    }

    .chatDetail {
      height: $containerHeight;
      border-left: 1px solid $soft-gray2;
      overflow-y: scroll;
    }
  }
}

.header {
  background-color: red;
}
