@import "../../constants";
@import "../../mixins";

.container {
  margin-bottom: 24px;
}

.container:last-child {
  margin-bottom: 0;
}

.label {
  @include fontSize10;
  font-weight: 500;
  color: $greyed-out-text;
  margin-bottom: 5px;
}

.value {
  @include fontSize12;
  font-weight: 400;

  &.valueBold:not(.valueMissing) {
    font-weight: 500;
  }

  &.valueMissing {
    color: $greyed-out-text;
  }
}
