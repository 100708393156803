@import "../../../constants.scss";

.container {
  margin-top: 40px;
  color: $soft-black;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;

  a {
    color: $coral;
  }
}
