@import "../../../constants.scss";

.container {
  display: inline-block;
  border: 2px solid $soft-gray2;
  border-radius: 40px;
  padding: 0 11px 0 30px;
  background: #f8f9fc;
  font-family: "Centra No2", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 31px;
  color: $soft-gray6;
  background-repeat: no-repeat;
  background-position: 8px 7px;

  &.paid {
    background-color: #eff8f3;
    border-color: #2f9b6b;
    background-image: url("../../../assets/icons/check_green_16.svg");

    @media (min-width: $breakpoint-sm) {
      background-image: url("../../../assets/icons/circle_check_green_16.svg");
    }
  }

  &.scheduled {
    background-image: url("../../../assets/icons/calendar_gray_16.svg");
  }

  &.draft {
    background-image: url("../../../assets/icons/document_gray_14.svg");
    background-position-x: 10px;
  }

  &.overdue {
    background-color: #fceeee;
    border-color: #f5554b;
    background-image: url("../../../assets/icons/warning_red_16.svg");
  }

  &.cancel {
    background-image: url("../../../assets/icons/cross_blue_13.svg");
    background-position-x: 10px;
    background-position-y: 10px;
  }

  &.hideText {
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    background-position: center;
    padding: 0;
    border: 0;
  }
}
