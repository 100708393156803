@import "../../../constants.scss";

.container {
  margin: 40px 0;

  .aside {
    margin-bottom: 30px;

    h2 {
      font-family: "Centra No2", sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
    }
  }

  @media (min-width: $breakpoint-sm) {
    display: flex;
    margin: 48px 0;
    border-top: 2px solid $soft-gray2;
    padding-top: 48px;

    &:first-child {
      border-top: 0;
      padding-top: 0;
    }

    .aside {
      width: 30%;
      padding-right: 20px;

      h2 {
        font-family: "F37Bobby", sans-serif;
        font-size: 21px;
        line-height: 36px;
      }

      p {
        margin: 4px 0 0;
      }
    }

    .fields {
      width: 60%;
    }
  }
}

.row {
  @media (min-width: $breakpoint-sm) {
    display: flex;

    > * {
      flex: 1;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
