@import "../../constants";

.withSidebar {
  overflow: hidden;
  div[id="root"] {
    overflow: hidden;
  }
}

@keyframes fromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.wrapper {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 900;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: rgba(255, 255, 255, 0.5);

  @media (min-width: $breakpoint-sm) {
    flex-direction: row;
    align-content: flex-start;
  }
}

.overlay {
  cursor: pointer;
  min-height: 68px;

  @media (min-width: $breakpoint-sm) {
    flex: 1;
    align-self: stretch;
    min-height: auto;
  }
}

.container {
  background-color: white;
  width: 100%;
  align-self: stretch;
  position: relative;
  box-shadow: 1px 0 24px rgba(40, 45, 55, 0.2);
  border-radius: 20px 20px 0 0;
  flex: 1;

  animation: fromBottom 0.25s;
  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 100px;
  }

  @media (min-width: $breakpoint-sm) {
    animation: fromRight 0.25s;
    //align-self: stretch;
    flex: auto;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 810px;
    min-height: 100%;
  }
}

.closeButton {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  color: $soft-gray5;
  right: 10px;
  top: 10px;

  @media (min-width: $breakpoint-sm) {
    right: 15px;
    top: 15px;
  }
}
