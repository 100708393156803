@import "../../mixins";

.container {
  @include fontSize36;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  font-family: F37Bobby;
  letter-spacing: -0.3px;
  background: #c3e1f5;
  box-shadow: 0px 2px 6px rgba(107, 114, 127, 0.3);
}

.container.centered {
  margin: 0 auto;
}

.container img {
  display: block;
  width: 100%;
  height: 100%;
}
