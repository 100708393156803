@import "../../constants";

.container {
  padding-top: $menu-height-mobile;
  height: 100px + $menu-height-mobile;
  background: #f3f5f9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .agentInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .avatar {
    margin: 20px;
    width: 60px;
    height: 60px;
  }

  .innerRight {
    display: flex;
    flex-direction: column;

    .supportName {
      font-size: 24px;
      line-height: 34px;
      color: $text-color;
    }

    .supportSub {
      font-size: 12px;
      line-height: 21px;
      color: $greyed-out-text;
    }
  }

  .detailsTrigger {
    cursor: pointer;
    margin: auto 24px;
    color: $soft-gray6;
    font-weight: 500;
    font-size: 14px;
  }
}

@media (min-width: $breakpoint-sm) {
  .container {
    padding-top: $menu-height-web;
    height: 100px + $menu-height-web;
  }
}
