@import "../../constants";
@import "../../mixins";

.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include fontSize14;

  .icon {
    margin-right: 16px;
    height: 24px;
    width: 24px;

    @media (min-width: $breakpoint-sm) {
      height: 32px;
      width: 32px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
