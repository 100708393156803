@import "../../constants";
@import "../../mixins";

$leftIndent: 0;

.container {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    @include fontSize12;
    color: $soft-gray4;
    padding: 0 $leftIndent;
  }

  .control {
    padding: 0;
  }

  .error {
    @include fontSize12;
    color: $coral-dark3;
    padding: 2px $leftIndent 0;
  }
}
