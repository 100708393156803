@import "../../constants";

.header {
  display: flex;
  align-items: center;
  width: 100%;

  h1 {
    margin-bottom: 0;
  }
}

.headerPhoto {
  flex: 0 0 80px;
  margin-right: 25px;
}

.editButton {
  float: right;
  z-index: 100;
  color: $link-color;
  cursor: pointer;
}
