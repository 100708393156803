@import "../../constants";
@import "../../mixins";

.container {
  border-bottom: 1px solid $soft-gray2;
  padding: 19px 0 15px;

  &:last-child {
    border-bottom: 0;
  }

  .label {
    @include fontSize10;
    color: $soft-gray4;
  }

  .main {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;

    .cardNumber {
      @include fontSize12;
      color: $soft-black;
    }

    .amount {
      @include fontSize12;
      color: $soft-black;
    }
  }
}
