@import "../../../constants.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #d2d4da;
  border-bottom: 1px dashed #d2d4da;
  padding-left: 30px;
  height: 56px;
  line-height: 56px;
  background-image: url("../../../assets/icons/circle_plus_blue_18.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  cursor: pointer;

  & + & {
    margin-top: 0;
    border-top: none;
  }

  & + *:not(&) {
    margin-top: 25px;
  }

  @media (min-width: $breakpoint-sm) {
    & + *:not(&) {
      margin-top: 32px;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  .label {
    color: $dark-blue2;
    font-weight: 500;
    font-size: 15px;
  }

  .total {
    font-size: 14px;
    line-height: 21px;

    span {
      margin-left: 8px;
      font-weight: 500;
      font-size: 17px;
    }
  }
}
