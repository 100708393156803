@import "../../../constants.scss";

.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  border: 1px solid $soft-gray2;
  border-radius: 2px;
  padding: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .clickable {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;

    .status {
      margin-right: 13px;
    }

    .info {
      flex: 1;
      .dueDate {
        margin-bottom: 3px;
        font-family: "Centra No2", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: $soft-gray5;
      }

      .client {
        font-family: "Centra No2", sans-serif;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: $soft-gray6;
      }
    }

    .totalAmount {
      font-family: "Centra No2", sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 0.01em;
      color: $soft-black;
    }
  }

  .actions {
    margin-top: 5px;
    margin-left: 0;
  }
}
