@import "../../constants";

.container {
  .option {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 22px 0;
    cursor: pointer;

    &:first-child {
      margin-top: 11px;
    }

    &:last-child {
      margin-bottom: 11px;
    }

    .control {
      display: block;
      border: 2px solid rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:checked {
        border-color: $coral;

        &:before {
          position: absolute;
          display: block;
          content: "";
          width: 11px;
          height: 11px;
          background-color: $coral;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .label {
      display: block;
      margin-left: 11px;
      font-family: "Centra No2", sans-serif;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.01em;
      cursor: pointer;

      @media (min-width: $breakpoint-sm) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &.disabled {
    .option {
      cursor: default;

      .control {
        border-color: $soft-gray4;
        cursor: default;

        &:checked {
          border-color: $soft-gray4;

          &:before {
            background-color: $soft-gray4;
          }
        }
      }

      .label {
        color: $soft-gray4;
        cursor: default;
      }
    }
  }
}
