@import "../../constants";
@import "../../mixins";

.container {
  margin-bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(107, 114, 127, 0.3);
  border-radius: 4px;

  &:first-child {
    margin-top: -60px;
  }

  @media (min-width: $breakpoint-sm) {
    &:first-child {
      margin-top: -72px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d2d4da;
  padding: 18px;
  @include fontSize16;
  font-weight: 500;
  color: $soft-black;

  @media (min-width: $breakpoint-sm) {
    padding: 24px 32px;
    font-weight: 400;
    line-height: 40px;
  }
}

.main {
  padding: 18px;

  &.noPadding {
    padding: 0;
  }

  @media (min-width: $breakpoint-sm) {
    padding: 24px;

    &.noPadding {
      padding: 0;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d2d4da;
  padding: 18px;
  @include fontSize14;

  a {
    color: $coral;
  }

  @media (min-width: $breakpoint-sm) {
    padding: 20px 32px;
  }
}
