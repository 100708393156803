@import "../../../constants.scss";

.container {
  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: #f8f9fc;

      tr {
        height: 45px;

        td {
          padding: 12px 16px;
          font-family: "Centra No2", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.01em;
          color: $soft-gray5;
        }
      }
    }

    tbody {
      tr {
        height: 70px;
        background-image: linear-gradient(
          to bottom,
          $soft-gray2 1%,
          rgba(0, 0, 0, 0) 1% 99%,
          $soft-gray2 99%
        );

        td {
          padding: 12px 16px;
          font-family: "Centra No2", sans-serif;
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: $soft-gray6;
        }
      }
    }
  }

  &.clickable {
    table {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }

  &.hoverable {
    table {
      tbody {
        tr {
          &:hover {
            background-image: linear-gradient(
              to bottom,
              $dark-blue2 1%,
              #f2f8fd 1% 99%,
              $dark-blue2 99%
            );
          }
        }
      }
    }
  }
}
