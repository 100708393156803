@import "../../constants";
@import "../../mixins";

button.button,
button.link,
a.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 16px;
  width: 100%;
  text-align: center;
  @include fontSize14;
  line-height: 36px;

  .icon {
    margin-right: 6px;
    width: 20px;

    img {
      display: block;
      width: 100%;
    }
  }

  @media (min-width: $breakpoint-sm) {
    padding: 0 36px;
    width: auto;

    .icon {
      margin-right: 10px;
      width: 24px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.small {
    width: auto;

    @media (min-width: $breakpoint-sm) {
      padding: 0 16px;
      font-size: 14px;
      line-height: 21px;

      .icon {
        margin-right: 6px;
        width: 20px;
      }
    }
  }
}

button.button,
a.button {
  border-radius: 24px;
  border: 1px solid;
}

a.button {
  // a doesn't respond to height. This is equivalent to height: 36px;
  padding-top: 7px;
  padding-bottom: 7px;
}

// Loading state

.loader {
  display: block;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.primary,
a.primary {
  background-color: $coral;
  color: white;
  border-color: $coral;

  &:hover {
    background-color: $coral-dark;
    border-color: $coral-dark;
  }

  &:active {
    background-color: $coral-dark2;
    border-color: $coral-dark2;
  }

  &:visited {
    color: white;
  }

  &.link {
    background-color: transparent;
    color: $coral;
  }

  .loader {
    border-color: white;
    border-top-color: transparent;
  }
}

button.secondary,
a.secondary {
  background-color: white;
  color: $coral;
  border-color: $coral;

  &:hover {
    background-color: rgba(245, 85, 75, 0.1);
  }

  &:active {
    background-color: rgba(245, 85, 75, 0.6);
  }

  .loader {
    border-color: $coral-dark;
    border-top-color: transparent;
  }
}

button.tertiary,
a.tertiary {
  background-color: white;
  color: $text-color;
  border-color: $text-color;
}

button.link {
  background-color: transparent;
}

// States

button.disabled {
  opacity: 0.5;
}

// Padding

button.noPadding {
  padding-left: 0;
  padding-right: 0;
}

button.widePadding {
  padding-left: 60px;
  padding-right: 60px;
}
