@import "../../mixins";

.emptyInstructions {
  @include singleColumn;
  text-align: center;
  margin: 40px 0;

  @media (min-width: $breakpoint-sm) {
    margin: 60px 20px;
  }

  h1 {
    font-size: 36px;
    line-height: 44px;
    color: $blue;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-sm) {
      margin-bottom: 14px;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 21px;
    font-weight: normal;
    margin-bottom: 14px;

    @media (min-width: $breakpoint-sm) {
      margin-bottom: 16px;
    }
  }

  p {
    margin-bottom: 24px;
  }
}
