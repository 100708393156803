@import "../../../constants";
@import "../../../mixins";

$menu-height-mobile: 51px;
$menu-height-desktop: 60px;

.container {
  padding-top: $menu-height-mobile;
  min-height: 100vh;

  @media (min-width: $breakpoint-sm) {
    padding-top: $menu-height-desktop;
  }
}

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  height: $menu-height-mobile;
  padding: 0 15px;
  background-color: white;
  z-index: 200;
  width: 100%;
  border-bottom: 2px solid $soft-gray2;

  .logo {
    width: 40px;

    img {
      display: block;
      width: 100%;
    }
  }

  .goalsIcon img {
    display: block;
  }

  @media (min-width: $breakpoint-sm) {
    align-items: flex-start;
    padding: 0;
    height: $menu-height-desktop;

    .logo {
      margin: 20px 0 0 40px;
      width: 167px;
    }

    .navigation {
      ul {
        display: flex;
        list-style: none;

        li {
          margin: 0 24px;
          height: 58px;

          a {
            display: block;
            height: 100%;
            padding: 24px 0 16px;
            color: $soft-gray6;
            font-family: "Centra No2", sans-serif;
            font-size: 14px;
            line-height: 18px;
          }

          &.selected,
          &:hover {
            &:after {
              content: "";
              position: absolute;
              right: 0;
              bottom: -2px;
              left: 0;
              height: 4px;
              background-color: #e43a2f;
            }

            a {
              font-weight: 500;
            }
          }
        }
      }
    }

    .profileDropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 2px solid $soft-gray2;
      padding: 0 68px 0 16px;
      height: 100%;

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-right: 10px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        font-family: "Centra No2", sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-transform: uppercase;
        color: $soft-gray5;
        background-color: $light-blue;

        img {
          display: block;
          width: 100%;
        }
      }

      .details {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        .name {
          font-family: "Centra No2", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $soft-black;
        }

        .company {
          font-family: "Centra No2", sans-serif;
          font-size: 12px;
          line-height: 16px;
          color: $soft-gray5;
        }
      }

      &:after {
        position: absolute;
        display: block;
        content: "";
        background-image: url("../../../assets/icons/arrow_dropdown_24.svg");
        right: 27px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }

      .dropdown {
        display: none;
        position: absolute;
        top: 58px;
        right: 0;
        left: -2px;
        background-color: white;
        border-color: $soft-gray2;
        border-style: solid;
        border-width: 0 2px 2px 2px;
        padding: 10px 0;

        ul {
          list-style: none;

          li {
            cursor: pointer;
            padding: 5px 20px;

            &,
            a {
              color: $soft-gray5;
            }

            &:hover {
              &,
              a {
                color: $soft-black;
              }
            }
          }
        }
      }

      &:hover {
        .dropdown {
          display: block;
        }
      }
    }
  }
}

.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .drawerHeader {
    background: #f3f5f9;
    padding-top: 24px;
    padding-bottom: 16px;
    text-align: center;

    .logoIcon {
      width: 55px;
      margin: 30px auto;
    }

    a {
      text-decoration: none;
    }

    .avatar {
      margin: auto auto 8px;
    }

    .profileName {
      text-align: center;
      color: #282d37;

      a {
        font-family: F37Bobby;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: #282d37;
      }
    }
  }

  .drawerContent {
    padding: 24px 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      text-decoration: none;
    }

    .drawerNav li {
      font-family: "Centra No2";
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
      list-style-type: none;

      a {
        color: #4e545e;
        padding-bottom: 4px;
      }
    }

    .drawerFooter .linkSmall {
      font-family: "Centra No2";
      font-size: 10px;
      line-height: 18px;
      color: #abaeb5;
      margin-bottom: 14px;
      margin-right: 40px;
    }

    .companyName {
      color: #abaeb5;
    }
  }
}

.pageHeader {
  padding: 30px 20px;
  background-color: #f8f9fc;

  @media (min-width: $breakpoint-sm) {
    padding: 50px 20px;
  }
}

.main {
  padding: 30px 20px;

  @media (min-width: $breakpoint-sm) {
    padding: 50px 20px;
  }

  h1 {
    font-size: 21px;
    line-height: 30px;

    @media (min-width: $breakpoint-sm) {
      font-size: 27px;
      line-height: 36px;
    }
  }

  p {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #4e545e;
  }
}
