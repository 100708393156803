@import "../../../constants.scss";

.container {
  cursor: pointer;
  padding: 0 10px;

  .dot {
    margin-bottom: 3px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $dark-blue2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
