@import "../../../constants.scss";

.container {
  .title {
    padding: 20px;
    border-bottom: 2px solid $soft-gray2;
    font-family: "F37Bobby", sans-serif;
    font-size: 19px;
    line-height: 28px;
    color: $soft-black;

    @media (min-width: $breakpoint-sm) {
      padding: 35px 30px 32px;
      font-size: 27px;
      line-height: 36px;
    }
  }

  .content {
    padding: 0 20px;
    max-width: 620px;
    margin: 0 auto;

    .header {
      padding: 20px 0;
      border-bottom: 2px solid $soft-gray2;
      text-align: center;

      @media (min-width: $breakpoint-sm) {
        border-bottom-width: 1px;
      }

      .total {
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        color: $soft-black;
      }

      .dueDate {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $soft-gray6;
      }
    }

    .footer {
      border-top: 2px solid $soft-gray2;
      padding: 20px 0 40px;
      text-align: center;

      @media (min-width: $breakpoint-sm) {
        border-top-width: 1px;
      }

      .company {
        margin-bottom: 3px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: $soft-black;

        @media (min-width: $breakpoint-sm) {
          font-size: 17px;
          line-height: 24px;
        }
      }

      .email {
        margin-bottom: 3px;
        a {
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
          color: #057ccc;
        }
      }

      .address {
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $soft-gray6;
      }

      .poweredBy {
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #6b727f;
      }

      .wingspanLogo {
        margin-top: 9px;
        width: 100px;
        margin: 0 auto;

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
