@import "../../../constants";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    background-color: white;
    box-shadow: $header-shadow;
    position: relative;
    z-index: 10;

    img {
      height: 16px;
    }

    @media (min-width: $breakpoint-sm) {
      padding: 20px;
    }
  }

  .main {
    flex-grow: 1;
    padding: 50px 20px;
    background-color: #f0f5fa;
    text-align: center;

    h1 {
      margin-bottom: 25px;
      font-size: 35px;
      line-height: 50px;

      @media (min-width: $breakpoint-sm) {
        font-size: 40px;
      }
    }

    p {
      color: $soft-black;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
    }

    .modest {
      font-size: 12px;
      font-weight: 300;
      line-height: 20px;

      a {
        color: $coral;
      }
    }

    @media (min-width: $breakpoint-sm) {
      padding: 80px 25px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
      margin-bottom: 10px;
    }
  }
}

.mainBox {
  margin: 40px auto;
  border-radius: 5px;
  padding: 20px;
  max-width: 840px;
  background-color: white;
  box-shadow: 0 10px 20px 0 rgba(40, 45, 55, 0.1);
  text-align: left;

  @media (min-width: $breakpoint-sm) {
    padding: 30px;
  }

  .description {
    color: $soft-black;
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-family: "Centra No2";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: 22px;
    border-bottom: 1px solid $soft-black;
    text-align: center;

    @media (min-width: $breakpoint-sm) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      font-size: 20px;
      line-height: 20px;
    }
  }
}
