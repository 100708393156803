@import "../constants";

.fixedFooter {
  position: fixed;
  bottom: 5vw;
  left: 5vw;
  width: 90vw;

  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}
