@import "../../../constants.scss";

.control {
  min-width: 100%;
  display: flex;
  align-items: center;
  background-color: #f8f9fc;
  border: 1px solid #f8f9fc;
  border-bottom: 1px solid $soft-gray5;
  padding: 10px 11px 11px;
  border-radius: 2px 2px 0 0;

  @media (min-width: $breakpoint-sm) {
    min-width: initial;
  }

  .icon {
    margin-right: 10px;
    color: #6b727f;
    height: 14px;
    width: 14px;
  }

  input {
    flex: 1;
    display: block;
    width: 100%;
    color: $soft-black;
    font-family: "Centra No2";
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;

    &::placeholder {
      color: $soft-black;
      opacity: 0.3;
      line-height: normal;
    }
  }

  .measurement {
    margin-left: 11px;
    font-family: "Centra No2", sans-serif;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: $soft-gray5;
  }

  &.focused {
    border-color: #057ccc;
  }

  &.withError,
  &.withError.focused {
    border-color: $coral-dark;
    background-color: #fceeee;
  }
}
