@import "../../constants.scss";

.container {
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: $breakpoint-sm) {
    max-width: 620px;
  }

  @media (min-width: $breakpoint-md) {
    max-width: 960px;
  }

  @media (min-width: $breakpoint-lg) {
    max-width: 1300px;
  }
}
