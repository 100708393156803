@import "../../../constants";

.container {
  margin-bottom: 25px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    display: block;
    margin-bottom: 10px;
    color: $soft-black;
    font-family: "Centra No2";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.07px;
    line-height: 12px;
  }

  .error {
    margin-top: 10px;
    color: $coral;
    font-family: "Centra No2";
    font-size: 12px;
    letter-spacing: -0.07px;
    line-height: 12px;
  }
}
