@import "../../../constants";

.container {
  margin-bottom: 25px;
  width: 100%;
  min-width: 100%;

  @media (min-width: $breakpoint-sm) {
    min-width: initial;
  }

  &.smallIndent {
    margin-bottom: 14px;
  }

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 32px;

    &.smallIndent {
      margin-bottom: 14px;
    }
  }

  .label {
    display: block;
    margin-bottom: 8px;
    color: $soft-gray5;
    font-family: "Centra No2", sans-serif;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0.01em;
  }

  .error {
    margin-top: 8px;
    padding-left: 23px;
    color: $soft-black;
    font-family: "Centra No2", sans-serif;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0.01em;
    background-image: url("../../../assets/icons/warning_16_red.svg");
    background-repeat: no-repeat;
    background-position: 0 2px;
  }
}
