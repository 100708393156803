@import "../../constants";
@import "../../mixins";

.container {
  display: flex;
  align-items: center;
  background-color: white;
  color: $dark-blue1;
  border-radius: 16px;
  padding: 4px 10px;
  cursor: pointer;
  margin-top: 10px;

  .name {
    padding-left: 5px;
    padding-right: 5px;
  }
}
