.icon {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  color: currentColor;

  .svg {
    display: block;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;

    fill: currentColor;
    *[fill] {
      fill: currentColor;
    }
  }
}
