.container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: "Helvetica Neue", Helvetica, "Segoe UI", Tahoma, Arial,
    sans-serif;

  .subject {
    color: #282d37;
    font-size: 24px;
    line-height: 34px;
  }

  .description {
    font-size: 16px;
    line-height: 28px;
    color: #282d37;
  }

  .primarySupportLabel {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 21px;
    color: #abaeb5;
  }
  .primarySupportContainer {
    display: flex;
    align-items: center;
    .avatar {
      margin: 10px;
      width: 40px;
      height: 40px;
    }
    .primarySupportInnerRight {
      display: flex;
      flex-direction: column;
      .supportName {
        font-size: 16px;
        line-height: 28px;
        color: #282d37;
      }
      .supportSub {
        font-size: 12px;
        line-height: 21px;
        color: #abaeb5;
      }
    }
  }

  .attributeContainer {
    margin-top: 20px;
    .attributeTitle {
      font-size: 12px;
      line-height: 21px;
      color: #abaeb5;
    }
    .attributeValue {
      font-size: 14px;
      line-height: 24px;
      color: #282d37;
      font-weight: 500;
    }

    .attributeDescription {
      font-size: 14px;
      line-height: 24px;
      color: #282d37;
    }
  }
}
