@import "../../../constants.scss";

.control {
  .input {
    width: 100%;
    border: 1px solid $soft-gray4;
    padding: 15px;
    border-radius: 3px;
    cursor: pointer;

    .value,
    .placeholder {
      max-width: calc(100% - 20px);
      color: $soft-black;
      font-family: "Centra No2";
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.13px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: $breakpoint-sm) {
        font-size: 20px;
      }
    }

    .placeholder {
      opacity: 0.3;
    }

    .icon {
      position: absolute;
      top: 19px;
      right: 16px;
      height: 10px;
      width: 10px;
      transform: rotate(-45deg);
      border-style: solid;
      border-width: 0 0 2px 2px;
      border-color: $coral;
    }
  }

  &.focused {
    .input {
      border-radius: 3px 3px 0 0;
      border-bottom: 0;

      .icon {
        transform: rotate(-225deg);
        top: 24px;
      }
    }
  }

  .dropdown {
    position: absolute;
    z-index: 50;
    top: 100%;
    width: 100%;
    background-color: white;
    border: 1px solid $soft-gray4;
    border-top: 0;
    border-radius: 0 0 3px 3px;

    .option {
      cursor: pointer;
      padding: 10px 15px;
      font-family: "Centra No2";
      font-size: 14px;
      letter-spacing: -0.13px;
      line-height: 20px;

      @media (min-width: $breakpoint-sm) {
        font-size: 16px;
      }

      &.selected {
        background-color: $soft-gray2;
        font-weight: 500;
      }

      &:hover {
        background-color: $soft-gray2;
      }
    }
  }
}
