@import "../../../constants";
@import "../../../mixins";

.planOption {
  height: 100%;
  border: 1px solid $soft-black;
  padding: 40px 15px;
  opacity: 0.5;
  border-radius: 3px;
  text-align: center;
  opacity: 0.5;
  cursor: pointer;

  &.selected {
    cursor: default;
    opacity: 1;
    border: 4px solid $coral;
    padding: 37px 12px;
  }

  .label {
    color: $soft-black;
    font-family: "Centra No2";
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    top: 10px;
    right: 10px;
    position: absolute;
    border-radius: 3px;
    background-color: $blue;
    padding: 5px 12px;
  }

  .price {
    color: $soft-black;
    font-family: "F37Bobby";
    font-size: 50px;
    letter-spacing: -0.01px;
    line-height: 50px;
  }

  .description {
    margin-top: 15px;
    color: $soft-black;
    font-family: "Centra No2";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 12px;
    text-transform: uppercase;

    span {
      color: $coral;
    }
  }
}
