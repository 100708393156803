@import "../../constants";
@import "../../mixins";

.container {
  h3 {
    font-family: "Centra No2";
    font-weight: 500;
  }

  p {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 2px 10px rgba(107, 114, 127, 0.3);
    border-radius: 8px;
    z-index: 2;
  }
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profilePhotos {
  margin-top: 2px;
  display: flex;
}

.profilePhoto {
  @include fontSize16;
  width: 32px;
  height: 32px;
  margin-left: -2px;

  @media (min-width: $breakpoint-sm) {
    width: 48px;
    height: 48px;
    margin-top: -6px;
  }
}
