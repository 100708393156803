@import "../../constants";
@import "../../mixins";

.container {
  cursor: pointer;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8eaef;
  padding-bottom: 16px;

  &.innerIndent {
    margin-bottom: 0;
    padding: 16px 24px;
  }

  &.active {
    background-color: $light-blue2;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 4px;
      background-color: #4bb0f3;
    }
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;

    &.innerIndent {
      padding-bottom: 24px;
    }
  }

  &:hover {
    background-color: $soft-gray2;
  }

  &.active,
  &.noHover {
    &:hover {
      background-color: transparent;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 4px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .category {
      color: $greyed-out-text;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .lastMessage {
    display: flex;
    align-items: center;
    font-size: 14px;

    .lastMessagePhotoContainer {
      margin-right: 15px;

      .lastMessagePhoto {
        width: 32px;
        height: 32px;
        font-size: 16px;
        background: $blue;
      }
    }

    .lastMessageMain {
      flex: 1;
    }

    .lastMessageName {
      font-weight: 500;
    }
  }
}
