@import "../../constants";
@import "../../mixins";

.container {
  text-align: center;
  margin: 20px;

  @media (min-width: $breakpoint-sm) {
    margin: 50px 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 20px;
  }
}
