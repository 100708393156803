@import "../../constants";
@import "../../mixins";

.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;

  &.avatarSpace {
    padding-left: 67px;
  }

  .text {
    @include fontSize12;
    border-radius: 8px;
    padding: 12px;
    max-width: 85%; // From and To message overlap

    // From agent
    color: $text-color;
    background: $soft-gray2;

    @media (min-width: $breakpoint-sm) {
      padding: 16px;
      max-width: 60%;
    }
  }

  &.isCurrentUser {
    justify-content: flex-end;
    .text {
      color: white;
      background: $dark-blue1;
    }
  }

  .avatar {
    margin-right: 13px;
    margin-left: 13px;
  }
}
