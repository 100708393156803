@import "../../../constants.scss";

.container {
  .button {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-family: "Centra No2", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #057ccc;
    cursor: pointer;
    margin-top: 15px;
  }

  .dropdown {
    position: absolute;
    z-index: 510;
    background-color: white;
    transform: translateX(-90%);
    border: 1px solid #e8eaef;
    box-shadow: 0px 1px 9px rgba(40, 45, 55, 0.15);
    border-radius: 2px;
    width: 180px;
    cursor: pointer;
    visibility: hidden;
    transition: none;

    &.positionTop {
      visibility: visible;
      top: auto;
      bottom: calc(100% + 15px);
    }

    &.positionBottom {
      visibility: visible;
      top: calc(100% + 15px);
    }

    @media (min-width: $breakpoint-sm) {
      transform: translateX(-50%);
    }
  }

  &.containerWithButtons {
    width: 100%;
  }

  .actions {
    width: 100%;

    li {
      text-align: right;
    }
  }

  ul {
    list-style: none;

    li {
      border-bottom: 1px solid $soft-gray2;
      padding: 13px 13px 13px 43px;
      font-family: "Centra No2", sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: $dark-blue2;
      background-repeat: no-repeat;
      background-position: 13px center;

      &:last-child {
        border-bottom: 0;
      }

      &.view {
        background-image: url("../../../assets/icons/search_blue_18.svg");
      }

      &.edit {
        background-image: url("../../../assets/icons/pencil_blue_13.svg");
      }

      &.delete {
        background-image: url("../../../assets/icons/cross_blue_13.svg");
      }

      &.cancel {
        background-image: url("../../../assets/icons/cross_blue_13.svg");
      }

      &.markPaid {
        background-image: url("../../../assets/icons/money_blue_16.svg");
      }

      &.downloadPdf {
        background-image: url("../../../assets/icons/download_blue_18.svg");
      }

      &.sendReminder {
        background-image: url("../../../assets/icons/calendar_blue_16.svg");
      }
    }
  }

  .overlay {
    position: fixed;
    z-index: 500;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
