@import "constants";

// Font sizes and line heights.
// Mixins are named for sizes on small screens.
@mixin fontSize10 {
  font-size: 10px;
  line-height: 18px;

  @media (min-width: $breakpoint-sm) {
    font-size: 12px;
    line-height: 18px;
  }
}

@mixin fontSize12 {
  font-size: 12px;
  line-height: 18px;

  @media (min-width: $breakpoint-sm) {
    font-size: 14px;
    line-height: 21px;
  }
}

@mixin fontSize14 {
  font-size: 14px;
  line-height: 21px;

  @media (min-width: $breakpoint-sm) {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin fontSize16 {
  font-size: 16px;
  line-height: 21px;

  @media (min-width: $breakpoint-sm) {
    font-size: 24px;
    line-height: 34px;
  }
}

@mixin fontSize24 {
  font-size: 24px;
  line-height: 34px;

  @media (min-width: $breakpoint-sm) {
    font-size: 36px;
    line-height: 50px;
  }
}

@mixin fontSize36 {
  font-size: 36px;
  line-height: 44px;

  @media (min-width: $breakpoint-sm) {
    font-size: 48px;
    line-height: 60px;
  }
}

/* Structural */

@mixin singleColumn {
  @media (min-width: $breakpoint-sm) {
    max-width: 564px;
    margin: 0 auto;
  }
}

@mixin twoColumns {
  @media (min-width: $breakpoint-sm) {
    max-width: 1048px;
    margin: 0 auto;
  }
}
