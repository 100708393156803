@import "../../constants";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-top: 24px + $menu-height-mobile;

  img {
    margin-top: 3px;
  }
}
