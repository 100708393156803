@import "../../constants";

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.withModal {
  overflow: hidden;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 28px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  width: 100%;
  height: 100vh;

  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 100px;
  }

  @media (min-width: $breakpoint-sm) {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    max-width: 630px;
    height: auto;
    max-height: 90vh;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    &.thin {
      max-width: 550px;
    }
  }
}

.closeButton {
  float: right;
  cursor: pointer;
  z-index: 100;
  color: $soft-gray5;
  margin: 5px 0 0;

  @media (min-width: $breakpoint-sm) {
    margin: -20px -22px 0 0;
  }
}
