@import "../../../constants.scss";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $coral;
  border-radius: 40px;
  padding: 18px 30px;
  width: fit-content;
  min-width: 150px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  height: 50px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .loader {
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    border-top-color: $coral;
    margin-top: -5px;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
  }
}

button.secondary {
  display: inline-block;
  width: fit-content;
  padding: 10px 30px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  color: $soft-black;
  opacity: 0.5;

  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
}
