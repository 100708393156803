@import "../../constants.scss";
@import "../../mixins.scss";

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.withLink {
    cursor: pointer;
  }

  .label {
    color: $soft-black;
    font-family: "Centra No2", sans-serif;
    @include fontSize14;
  }

  .completeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 2px solid $coral;
    border-radius: 50%;

    &:before {
      position: relative;
      display: block;
      content: "";
      height: 6px;
      width: 11px;
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: $soft-black;
      transform: rotate(135deg);
      top: -1px;
    }
  }

  .linkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 2px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;

    &:before {
      position: relative;
      display: block;
      content: "";
      height: 7px;
      width: 7px;
      transform: rotate(-45deg);
      border-style: solid;
      border-color: $soft-black;
      border-width: 0 2px 2px 0;
      left: -2px;
    }
  }
}

.progress {
  margin-right: -5px;

  @media (min-width: $breakpoint-sm) {
    margin-right: -11px;
  }
}
