@import "../../constants";
@import "../../mixins";

h4.title {
  margin-bottom: 12px;
  padding-right: 10px;
  display: inline-block;
}

.freeTrial {
  color: white;
  background-color: $dark-lavender2;
  border-radius: 6px;
  padding: 6px 10px;
  margin-bottom: 10px;
  display: inline-block;
}

.label {
  .hint {
    position: absolute;
    top: -18px;
    @include fontSize10;
    color: $soft-gray4;
  }
}
