:global {
  [contentEditable="true"]:empty:not(:focus):before {
    content: attr(data-ph);
    color: #282d37;
    font-size: 14px;
    line-height: 24px;
    font-family: "Helvetica Neue", Helvetica, "Segoe UI", Tahoma, Arial,
      sans-serif;
  }
}

.container {
  display: flex;
  align-items: center;
  border-top: 1px solid #e8eaef;
  min-height: 64px;

  .attach {
    margin-left: 16px;
  }

  textarea {
    resize: none;
    height: 100%;
    flex-grow: 1;
    font-size: 14px;
    line-height: 24px;
    padding: 16px;
    font-family: "Helvetica Neue", Helvetica, "Segoe UI", Tahoma, Arial,
      sans-serif;
  }
  .sendButton {
    height: 100%;
    flex-grow: 0;
    font-size: 14px;
    line-height: 24px;
    font-family: "Helvetica Neue", Helvetica, "Segoe UI", Tahoma, Arial,
      sans-serif;
    color: #abaeb5;
    cursor: pointer;
    margin-right: 24px;
    user-select: none;
    &:hover {
      color: #66686d;
    }
  }
}
