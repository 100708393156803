@import "../../constants";
@import "../../mixins";

.agentAssignment {
  padding-top: 60px;
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    padding: 30px 0 15px;
  }

  h1.hero {
    color: $blue;
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 36px;
  }

  h1 {
    font-size: 24px;
    line-height: 34px;
  }

  p {
    @include fontSize14;
  }

  .container {
    @media (min-width: $breakpoint-sm) {
      margin-bottom: 30px;
    }
  }
}

.profilePhoto {
  margin: 0 auto 30px;
}
