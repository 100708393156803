@import "../../../constants";

.container {
  .option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .control {
      display: block;
      border: 2px solid $soft-gray5;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      cursor: pointer;

      &:checked {
        border-color: $dark-blue2;

        &:before {
          position: absolute;
          display: block;
          content: "";
          width: 11px;
          height: 11px;
          background-color: $dark-blue2;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .label {
      display: block;
      margin-left: 11px;
      font-family: "Centra No2", sans-serif;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.01em;
      cursor: pointer;
    }
  }
}
