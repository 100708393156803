@import "../../constants";
@import "../../mixins";

.fileUploader {
  .uploadBox {
    border: 1px dashed $greyed-out-text;
    padding: 20px;
    text-align: center;

    &:hover {
      border-color: $coral;
      cursor: pointer;
    }

    p {
      margin: 0;

      &:first-child {
        margin-bottom: 10px;
      }
    }

    .fileCopy {
      margin: 0 6px -6px 0;
    }
  }
}

ul.uploadedList {
  list-style-type: none;
  margin: 16px 0;

  li {
    @include fontSize14;
    margin-bottom: 16px;
    font-weight: 500;

    .iconFile {
      margin-right: 6px;
      vertical-align: bottom;
    }
  }

  .remove {
    color: $greyed-out-text;
    position: absolute;
    right: 0;
  }
}
