@import "../../../constants.scss";

.control {
  min-width: 100%;

  @media (min-width: $breakpoint-sm) {
    min-width: initial;
  }

  textarea {
    display: block;
    width: 100%;
    background-color: #f8f9fc;
    border: 1px solid #f8f9fc;
    border-bottom-color: $soft-gray5;
    padding: 11px 12px;
    border-radius: 2px 2px 0 0;
    color: $soft-black;
    font-family: "Centra No2";
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;

    &::placeholder {
      color: $soft-black;
      opacity: 0.3;
      line-height: normal;
    }
  }

  &.focused {
    textarea {
      border-color: #057ccc;
    }
  }

  &.withError,
  &.withError.focused {
    textarea {
      border-color: $coral-dark;
      background-color: #fceeee;
    }
  }
}
