.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h3 {
    font-weight: 500;
  }

  .profilePhotoSmall {
    font-size: 24px;
    line-height: 34px;
    width: 40px;
    height: 40px;
    margin-right: 14px;
  }
}
