@import "../../constants.scss";

.slick-dots {
  text-align: center;

  li {
    display: inline-block;
    margin: 0 12px;

    button {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid #282d37;
      opacity: 0.25;
      text-indent: -9999px;
    }

    &.slick-active {
      button {
        background-color: $coral;
        opacity: 1;
        border-color: $coral;
      }
    }
  }
}
