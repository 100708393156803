@import "../../constants";

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%, 0);
  color: red;
}

.content {
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: solid 2px #e8eaef;
  padding: 20px;

  @media (min-width: $breakpoint-sm) {
    padding: 32px;
    align-items: center;
  }

  .headerTitle {
    display: flex;
    flex-direction: column;
    font-size: 27px;
    line-height: 36px;
    color: #282d37;
  }

  p {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #4e545e;
    margin: 5px 0 0 0;
  }
}

.subheader {
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px #e8eaef;
  padding: 20px;

  @media (min-width: $breakpoint-sm) {
    padding: 32px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    flex-direction: column-reverse;

    & > * {
      width: 100%;
      margin-bottom: 15px;
    }

    .actionsButton {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: $breakpoint-sm) {
      flex-direction: row;
      align-items: center;

      & > * {
        width: auto;
        margin-bottom: 0;
        margin-left: 12px;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #e8eaef;
      padding: 15px 0;
    }

    @media (min-width: $breakpoint-sm) {
      flex-direction: row;

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: none;
        padding: 0;
      }

      div + div {
        margin-left: 60px;
      }
    }
  }

  .label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #6b727f;
  }

  .value {
    display: block;
    font-family: "Centra No2", sans-serif;
    color: #282d37;
    font-size: 17px;
    line-height: 24px;

    @media (min-width: $breakpoint-sm) {
      font-family: F37Bobby, sans-serif;
      font-size: 21px;
      line-height: 36px;
    }
  }
}

.section {
  padding: 0 20px;

  @media (min-width: $breakpoint-sm) {
    padding: 0 32px;
  }

  .document {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #057ccc;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .docIcon {
    margin-right: 10px;
    width: 15px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/icons/document_blue_14.svg");
    background-size: contain;
  }

  h2 {
    font-family: F37Bobby, sans-serif;
    font-size: 19px;
    line-height: 28px;
    color: #282d37;
  }

  h3 {
    font-family: Centra No2, monospace;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #282d37;
    margin-top: 32px;
  }

  p {
    margin-top: 12px;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #4e545e;
  }
}
