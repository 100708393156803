// Palette
$coral: #f5554b;
$coral-dark: #e43a2f;
$coral-dark3: #9f170f;
$coral-dark2: #c52e24;

$soft-black: #282d37;
$soft-gray6: #4e545e;
$soft-gray5: #6b727f;
$soft-gray4: #abaeb5;
$soft-gray2: #e8eaef;

$blue: #c3e1f5;
$dark-blue2: #057ccc;
$dark-blue1: #4cb0f3;
$light-blue: #dbf0ff;
$light-blue2: #e7f0f7;

$beige: #ffdcbe;
$pink: #ffecd2;
$pink-light: #ffdcd2;
$lavender: #e6d7eb;
$dark-lavender2: #cb5def;

// UI
$text-color: $soft-black;
$link-color: $coral;
$hover-link-color: $coral-dark;
$clicked-link-color: $coral-dark2;
$greyed-out-text: $soft-gray4;
$disabled-color: $soft-gray4; // UB designed as $soft-gray3, but too hard to see

// Forms
$form-left-indent: 0;

// Responsive breakpoints
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

// Menu height, necessary for using headers
$menu-height-mobile: 42px;
$menu-height-web: 56px;

$header-shadow: 0px 1px 3px rgba(40, 45, 55, 0.2),
  0px 2px 1px rgba(40, 45, 55, 0.12), 0px 1px 1px rgba(40, 45, 55, 0.14);
