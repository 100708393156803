@import "../../../constants.scss";

.control {
  display: flex;
  align-items: center;

  .box {
    margin-right: 12px;
    border: 2px solid $dark-blue2;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color ease 0.3s;
  }

  .label {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    cursor: pointer;
  }

  &.checked {
    .box {
      background-color: $dark-blue2;
      background-image: url("../../../assets/icons/check_white_16.svg");
      background-position: center;
    }
  }
}
