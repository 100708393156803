@import "../../constants.scss";

.container {
  .title {
    margin-bottom: 5px;
    color: $soft-black;
    font-family: "Centra No2", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 20px;
  }

  .description {
    margin-bottom: 10px;
    opacity: 0.75;
    color: $soft-black;
    font-family: "Centra No2", sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.09px;
    line-height: 20px;
  }
}
