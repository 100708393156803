@import "../../../constants.scss";

.container {
  margin-bottom: 30px;

  h1 {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }

  .backButton {
    display: block;
    margin-bottom: 23px;
    padding-left: 17px;
    font-family: "Centra No2", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: $dark-blue2;
    background-image: url("../../../assets/icons/arrow_left_blue_9.svg");
    background-repeat: no-repeat;
    background-position-y: 2px;
    cursor: pointer;

    @media (min-width: $breakpoint-lg) {
      position: absolute;
      top: 20px;
      left: -66px;
      margin-bottom: 0;
      padding-left: 0;
      width: 13px;
      height: 20px;
      background-image: url("../../../assets/icons/arrow_left_gray_13.svg");
      background-position-y: 0px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .face {
    overflow: hidden;
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    img {
      display: block;
      width: 100%;
    }
  }

  &.centered {
    text-align: center;

    .face {
      margin: 0 auto;
    }
  }

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 40px;

    h1 {
      margin-bottom: 6px;
    }

    .face {
      margin-bottom: 30px;
      width: 60px;
      height: 60px;
    }
  }
}
