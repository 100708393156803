@import "../../../constants.scss";

.container {
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 0;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

    h2 {
      font-family: "Centra No2", sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: $soft-black;
      letter-spacing: 0.01em;
    }
  }

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    header {
      h2 {
        font-family: "F37Bobby", sans-serif;
        font-size: 21px;
        line-height: 36px;
        letter-spacing: inherit;
      }
    }
  }
}
